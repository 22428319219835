import React, { useState } from 'react';
import { MdOutlineShare } from 'react-icons/md';

const ButtonWithPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="flex items-center justify-start pt-5 ">
      <button
        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex justify-center items-center gap-2"
        onClick={handleOpenPopup}
      >
        <MdOutlineShare size={22}/>
        Share
      </button>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className=" flex flex-col gap-2 justify-center items-center rounded shadow-lg max-w-sm w-full">
            <img
            alt='QR'
            className='w-48 h-40 object-contain bg-white rounded-lg p-4'
            src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/QRCode-86.png?alt=media&token=13d0a92f-5637-4373-b899-34c69d294467'
            />
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
              onClick={handleClosePopup}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonWithPopup;
