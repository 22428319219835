import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";

function AppliesJob() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Applies To Job">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Applies To Job"
          Subheader="Applies To Job"
          bgimg={`${rpdata?.gallery?.length > 10 ? rpdata?.gallery?.[106] : rpdata?.stock?.[10]}`}
        />

        <div className="w-4/5 h-auto p-5 rounded-xl mx-auto my-20 bg-white shadow-xl">
          <h2 className="text-center pb-5">Fill In The Requested Fields
          </h2>
          <div class="elfsight-app-0229f1ff-aab7-436e-8f92-2e4ffe326007" data-elfsight-app-lazy></div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default AppliesJob;
